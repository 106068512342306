<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Perjanjian Hari Ini</b> Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/appointment/add')"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :items="items"
              :isToday="true"
              :fields="fields"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              @btnDeleteOnClick="btnDeleteOnClick"
              @filter="generalFilter"
              @filterByPatient="generalFilter"
              @pageOnClick="pageOnClick"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import Table from '@/component/appointments/Table.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { getUser } from '@/core/services/jwt.service.js'

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      // Filter
      filter: {
        today: 'true',
        appointment_status_id: '',
        doctor_id: '',
        patient_name: '',
        start_date: '',
        end_date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
      // current user
      currentUser: getUser()
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let route
      if (this.currentUser.user_type_id == 1) {
        route = `appointments/get-by-doctor/${this.currentUser.user_id}`
      } else {
        route = 'appointments'
      }
      let filterParams = `&today=${this.filter.today}&appointment_status_id=${this.filter.appointment_status_id}&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(route, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    async generalFilter(sended) {
      this.filter.appointment_status_id = sended.appointment_status_id
      this.filter.doctor_id = sended.doctor_id
      this.filter.patient_name = sended.patient_name
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('appointments/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")
        if (access_right[a] == "5002") {
          this.manipulateBtn = true
        }
      }
    },

  },

  mounted() {
    // Breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [ 
      { title: "Perjanjian" },
      { title: "Hari Ini" },
    ])
    // Table List
    this.pagination()
    this.setActiveMenu()
  },

}
</script>
